import LANG_BRAZILIAN_TAXES from '../../../lang/lang.brazilianTaxes'

import '../../../sass/tax-table.scss'

const BrazilianTaxes = (
    iofExchange: number,
    iofCard: number,
    conversionBottomValue: number,
    conversionTopValue: number,
    simplifiedTax: number,
    renderLanguage: string
) => {
    const lang = (LANG_BRAZILIAN_TAXES as { [key: string]: any })[
        renderLanguage
    ]
    const renderImportTax = conversionTopValue > 50

    const cardValues = () => {
        if (renderImportTax)
            return (
                <>
                    <td>{(simplifiedTax + iofCard).toFixed(2)}</td>
                    <td>
                        {(
                            conversionBottomValue +
                            simplifiedTax +
                            iofCard
                        ).toFixed(2)}
                    </td>
                </>
            )
        return <td>{(conversionBottomValue + iofCard).toFixed(2)}</td>
    }

    const exchangeValues = () => {
        if (renderImportTax)
            return (
                <>
                    <td>{(simplifiedTax + iofExchange).toFixed(2)}</td>
                    <td>
                        {(
                            conversionBottomValue +
                            simplifiedTax +
                            iofExchange
                        ).toFixed(2)}
                    </td>
                </>
            )
        return <td>{(conversionBottomValue + iofExchange).toFixed(2)}</td>
    }

    return (
        <div className="tax-table--wrapper">
            <table className="tax-table">
                <thead className="tax-table__head">
                    <tr>
                        <th></th>
                        <th>
                            <abbr title={lang.iof[1]}>{lang.iof[0]}</abbr>
                        </th>
                        {renderImportTax && (
                            <th>
                                <abbr title={lang.importTax[1]}>
                                    {lang.importTax[0]}
                                </abbr>
                            </th>
                        )}
                        <th>
                            <abbr title={lang.total[1]}>{lang.total[0]}</abbr>
                        </th>
                    </tr>
                </thead>
                <tbody className="tax-table__body">
                    <tr>
                        <td>{lang.exchange}</td>
                        <td>{iofExchange}</td>
                        {exchangeValues()}
                    </tr>
                    <tr>
                        <td>{lang.card}</td>
                        <td>{iofCard}</td>
                        {cardValues()}
                    </tr>
                </tbody>
                <tfoot>
                    {renderImportTax && (
                        <>
                            {lang.footnotes.importTax.map(
                                (element: string, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td colSpan={4}>{element}</td>
                                        </tr>
                                    )
                                }
                            )}
                        </>
                    )}
                    {lang.footnotes.general.map(
                        (element: string, i: number) => {
                            return (
                                <tr key={i}>
                                    <td colSpan={4}>{element}</td>
                                </tr>
                            )
                        }
                    )}
                </tfoot>
            </table>
        </div>
    )
}

export default BrazilianTaxes
