const LANG_BRAZILIAN_TAXES = {
    BR: {
        iof: ['IOF', 'Imposto sob operação físcal'],
        importTax: [
            'IMPORT + IOF',
            'Soma da Taxa de Importação com o valor de IOF (Imposto sob operação fiscal)'
        ],
        total: [
            'Total',
            'Soma do valor em Reais, Imposto sob operação fiscal (IOF) e  Taxa de Importação'
        ],
        exchange: 'Câmbio',
        card: 'Cartão',
        footnotes: {
            importTax: [
                'Taxa simplificada 60% até 500USD',
                'Os impostos exibidos apresentam uma estimativa com base no cálculo padrão de 60% para Imposto de Importação.'
            ],
            general: [
                'Não são considerados nos cálculos os limites e isenções previstos em lei.',
                'O Dólar Free não se responsabiliza divergências no imposto cobrado sobre a mercadoria.'
            ]
        }
    },
    EN: {
        iof: ['IOF', 'Tax over fiscal operations'],
        importTax: [
            'IMPORT + IOF',
            'Sum of the Import tax + IOF (Tax over fiscal operations)'
        ],
        total: [
            'Total',
            'Sum of the value in Brazilian Reals. Taxes over the fiscal operation (IOF) and import tax',
        ],
        exchange: 'Direct Exchange',
        card: 'Card',
        footnotes: {
            importTax: [
                'Simplified tax 60% until 500USD',
                'The taxes displayed here present only an estimate based on the default tax of 60% for Brazilian import taxes.'
            ],
            general: [
                'Tax exemptions are not considered in the tax calculations.',
                'Dólar Free isn\'t responsible for divergencies on taxes over purchases. '
            ]
        }
    },
    ES: {
        iof: ['IOF', 'Impuesto bajo transacción fiscal'],
        importTax: [
            'IMPORT + IOF',
            'Impuesto bajo transacción fiscal'
        ],
        total: [
            'Total',
            'Suma del valor en Reales, Impuesto bajo operación tributaria (IOF) e Impuesto de Importación'
        ],
        exchange: 'Intercambio',
        card: 'Tarjeta',
        footnotes: {
            importTax: [
                'Tarifa simplificada 60% hasta 500USD',
                'Los impuestos que se muestran son una estimación basada en el cálculo predeterminado del 60 % para el impuesto de importación.'
            ],
            general: [
                'Los límites y exenciones previstos en la legislación brasileña no se consideran en los cálculos.',
                'Dólar Free no se responsabiliza por discrepancias en el impuesto cobrado sobre las mercancías.'
            ]
        }
    }
}

export default LANG_BRAZILIAN_TAXES