const firebaseConfig = {
    apiKey: 'AIzaSyAti81WwljZ2oNZY2dfCgIEh1-bN6H5zts',
    authDomain: 'dolar-free.firebaseapp.com',
    databaseURL: 'https://dolar-free-default-rtdb.firebaseio.com',
    projectId: 'dolar-free',
    storageBucket: 'dolar-free.appspot.com',
    messagingSenderId: '370665955503',
    appId: '1:370665955503:web:a954017fecba3dc87d745a',
    measurementId: 'G-DSKR7DEYKM'
}

export default firebaseConfig
