const LANG_CURRENCIES = {
    BR: {
        ARS: ['Peso Argentino', 'Pesos Argentinos'],
        BRL: ['Real Brasileiro', 'Reais Brasileiros'],
        EUR: ['Euro', 'Euros'],
        GBP: ['Libra Esterlina', 'Libras Esterlinas'],
        PYG: ['Guarani Paraguaio', 'Guaranis Paraguaios'],
        USD: ['Dólar Americano', 'Dólares Americanos'],
        UYU: ['Peso Uruguaio', 'Pesos Uruguaios']
    },
    EN: {
        ARS: ['Argentine Peso', 'Argentine Pesos'],
        BRL: ['Brazilian Real', 'Brazilian Reais'],
        EUR: ['Euro', 'Euros'],
        GBP: ['British Sterling', 'British Sterlings'],
        PYG: ['Paraguayan Guarani', 'Paraguayan Guaranis'],
        USD: ['American Dollar', 'American Dollars'],
        UYU: ['Uruguayan Peso', 'Uruguayan Pesos']
    },
    ES: {
        ARS: ['Peso Argentino', 'Pesos Argentinos'],
        BRL: ['Real Brasileño', 'Reales Brasileños'],
        EUR: ['Euro', 'Euros'],
        GBP: ['Libra Esterlina', 'Libras Esterlinas'],
        PYG: ['Guaraní Paraguaio', 'Guaranís Paraguaios'],
        USD: ['Dólar Americano', 'Dólares Americanos'],
        UYU: ['Peso Uruguayo', 'Pesos Uruguayos']
    }
}

export default LANG_CURRENCIES
