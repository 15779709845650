function NotFound() {
    // TODO

    return (
        <>
            <br />
            página não encontrada
            <br />
            page not found
            <br />
            página no encontrada
            <br />
        </>
    )
}

export default NotFound
