const LANG_ROUTES = {
    BR: {
        about: ['/sobre', 'Sobre'],
        main: ['/br', 'Dólar Free'],
        map: ['/pontos-de-referencia', 'Pontos de Referência'],
        gdpr: ['/gdpr', 'GDPR'],
        privacyPolicy: ['/política-de-privacidade', 'Política de Privacidade'],
        termsOfUse: ['/termos-de-uso', 'Termos de Uso'],
        notFound: ['/pagina-nao-encontrada', 'Página náo Encontrada']
    },
    EN: {
        about: ['/about', 'About'],
        main: ['/en', 'Dólar Free'],
        map: ['/reference-points', 'Reference Points'],
        gdpr: ['/gdpr', 'GDPR'],
        privacyPolicy: ['/privacy-policy', 'Privacy Policy'],
        termsOfUse: ['/terms-of-use', 'Use Terms'],
        notFound: ['/page-not-found', 'Page not Found']
    },
    ES: {
        about: ['/sobre', 'Sobre'],
        main: ['/es', 'Dólar Free'],
        map: ['/puntos-de-referencia', 'Puntos de Referencia'],
        gdpr: ['/lgpd', 'LGPD'],
        privacyPolicy: ['/politica-de-privacidad', 'Política de Privacidad'],
        termsOfUse: ['/terminos-de-uso', 'Términos de Uso'],
        notFound: ['/pagina-no-encontrada', 'Página no Encontrada']
    }
}

export default LANG_ROUTES