// DB
// https://firebase.google.com/docs/database/web/read-and-write

import firebaseConfig from '../../app/firebase.config'

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// import { toast } from 'react-toastify'
import { getDatabase, ref, child, get } from 'firebase/database'

import { LANG_CURRENCIES } from '../../app/lang.config'
import BrazilianTaxes from './convert/BrazilianTaxes'

import '../../sass/convert.scss'

type exchangeBase = {
    base: string
    date: string
    timestamp: number
}

type exchangeRates = {
    ARS: number
    BRL: number
    EUR: number
    GBP: number
    PYG: number
    UYU: number
}

function Convert() {
    const location = useLocation()
    const [base, setExchangeBase] = useState<exchangeBase>({
        base: 'USD',
        date: 'YYYY-MM-DD',
        timestamp: 0
    })

    const [conversion, setConversion] = useState('BRL')
    const [conversionBottomValue, setConversionBottomValue] = useState(0)
    const [conversionTopValue, setConversionTopValue] = useState(0)
    const [iofExchange, setIOFExchange] = useState(0)
    const [iofCard, setIOFCard] = useState(0)
    const [selectVersion, setSelectVersion] = useState(0)
    const [rate, setExchangeRates] = useState<Array<exchangeRates>>([])
    const [simplifiedTax, setSimplifiedTax] = useState(0)
    const [lang, setLang] = useState<any>(LANG_CURRENCIES.EN)
    const [currentLang, setCurrentLang] = useState<string>('EN')

    // Initialize Firebase
    const firebase = initializeApp(firebaseConfig)
    const analytics = getAnalytics(firebase)
    const dbRef = ref(getDatabase())

    const maxLength = 7

    useEffect(() => {
        getRates()
        initLang()
    }, [])

    useEffect(() => {
        initLang()
    }, [location])

    const calc = (conversionRate = 0, value: number, multiply = true) => {
        const amout =
            conversionRate === 0
                ? parseFloat(
                      (rate as { [key: string]: any })[conversion] as string
                  )
                : conversionRate

        console.log('CONVERSION', conversion)
        console.log('AMOUNT', amout)

        console.log('*', value * amout)
        console.log('/', value / amout, value / amout > 0)
        console.log(multiply)

        if (multiply) return value * amout
        else if (amout > 0) return value / amout
        else return 0
    }

    const getRates = () => {
        get(child(dbRef, 'exchange'))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val()
                    const rates = data.rates

                    console.log(
                        'SNAPSHOT >>>',
                        snapshot.val(),
                        'RATES >>>',
                        console.log(rates)
                    )

                    setExchangeBase({
                        base: data.base,
                        date: data.date,
                        timestamp: data.timestamp
                    })

                    setExchangeRates(rates)

                    console.log('RATES', rates)
                } else {
                    console.log('No data available')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const initLang = () => {
        const storedLanguage = localStorage.getItem('currentLanguage')

        if (storedLanguage !== null) {
            const storedLanguageUppercase = storedLanguage.toUpperCase()

            setLang(
                (LANG_CURRENCIES as { [key: string]: any })[
                    storedLanguageUppercase
                ] as string
            )

            setCurrentLang(storedLanguageUppercase)
        }
    }

    const returnConversionBottomValue = (value: number) => {
        if (value.toString().length <= maxLength) {
            const total = calc(0, value, false)

            console.log(value, total)

            setConversionBottomValue(value)
            setConversionTopValue(parseFloat(total.toFixed(2)))

            taxInfo(total)
            setSelectVersion(selectVersion - 1)
        }
    }

    const returnConversionTopValue = (value: number) => {
        if (value.toString().length <= maxLength) {
            const total = calc(0, value)

            setConversionTopValue(value)
            setConversionBottomValue(parseFloat(total.toFixed(2)))

            taxInfo(total)

            setSelectVersion(selectVersion + 1)
        }
    }

    const selectCurrency = (value: string) => {
        const total = calc(
            parseFloat((rate as { [key: string]: any })[value] as string),
            conversionTopValue
        )

        setConversion(value)

        setConversionTopValue(conversionTopValue)
        setConversionBottomValue(parseFloat(total.toFixed(2)))

        taxInfo(total)

        setSelectVersion(selectVersion + 1)
    }

    const taxInfo = (value: number) => {
        if (value > 0) {
            const base = value / 100

            setIOFExchange(parseFloat((base * 1.1).toFixed(2)))
            setIOFCard(parseFloat((base * 6.38).toFixed(2)))
            setSimplifiedTax(parseFloat((base * 60).toFixed(2)))
        } else {
            setIOFExchange(0)
            setIOFCard(0)
            setSimplifiedTax(0)
        }
    }

    return (
        <div className="convert">
            <label className="convert--label">
                {lang.USD[conversionTopValue === 1 ? 0 : 1]}
            </label>
            <input
                className="convert--input"
                type="number"
                min="0"
                placeholder="USD"
                maxLength={maxLength}
                value={conversionTopValue}
                onChange={(e) =>
                    returnConversionTopValue(parseFloat(e.target.value))
                }
            />
            <select
                className="convert--select"
                key={`sv-${selectVersion}`}
                defaultValue={conversion}
                onChange={(e) => selectCurrency(e.target.value)}
            >
                {Object.keys(rate).map((currecy, key) => (
                    <option key={`s-${key}-v-${selectVersion}`} value={currecy}>
                        {
                            (
                                (lang as { [key: string]: any })[
                                    currecy
                                ] as string
                            )[conversionBottomValue === 1 ? 0 : 1]
                        }
                    </option>
                ))}
            </select>
            <input
                className="convert--input"
                type="number"
                min="0"
                placeholder="BRL"
                maxLength={maxLength}
                value={conversionBottomValue}
                onChange={(e) =>
                    returnConversionBottomValue(parseFloat(e.target.value))
                }
            />
            {conversion === 'BRL' &&
                iofExchange > 0 &&
                iofCard > 0 &&
                BrazilianTaxes(
                    iofExchange,
                    iofCard,
                    conversionBottomValue,
                    conversionTopValue,
                    simplifiedTax,
                    currentLang
                )}
        </div>
    )
}

export default Convert
